<template>
  <div class="fs-unmask standard-aan-msg">
    <div class="standard-aan-msg__title">
      Please review the following and accept to continue.
    </div>

    <v-card
      class="standard-wizard__step__alert"
      data-test="softlessAan"
      flat>
      <v-card-text>
        <img
          :src="logoUrl"
          alt="Logo"
          class="mb-3 modal-logo mt-3">
        <p class="consumer-app-id mb-1">
          Application ID: {{ humanReadableApplicationId }}
        </p>
        <p class="mb-1">
          {{ applicantName }}
        </p>
        <p
          v-private
          v-fs-exclude
          class="mb-1">
          {{ loanApplyWizardData.address_1 }}
        </p>
        <p
          v-private
          v-fs-exclude
          class="mb-1">
          {{ loanApplyWizardData.city }} {{ loanApplyWizardData.state }}
        </p>
        <p v-private v-fs-exclude>
          {{ loanApplyWizardData.zip_code }}
        </p>
        <p>{{ dateToday }}</p>

        <p>Dear {{ applicantName }}:</p>

        <p>
          Thank you for your applying for a
          <strong v-private>{{ requestedLoanAmountLabel }}</strong> loan through
          Momnt Technologies, Inc. (“Momnt”). Your request for the loan and
          specific loans terms were carefully considered by the lenders listed below
          and we regret that none of them was able to approve your application.
          While we are unable to offer you the full amount requested, we have approved you for
          a loan amount of <strong v-private>{{ approvedAmountHardPullLabel }}</strong>.
          If this offer is acceptable to you, please accept the offer
          in the Momnt mobile application by {{ sevenDaysAdvance }} to continue.
        </p>

        <div class="mb-3">
          <lender-list :lenders="[lenderInfo]" />
        </div>

        <p>
          You may request a statement of specific reasons why each lender denied your
          application for credit if you do so within 60 days from the date you receive this
          letter. We will provide you with the statement of reasons within 30 days after
          receiving your request.  Please direct your request to
          <a
            v-fs-exclude
            :href="`mailto:${brandObject.footer_object.support_email}`"
            class="aan-address__mail">
            {{ brandObject.footer_object.support_email }}.
          </a>
        </p>

        <strong class="aan-consumer-rights-color">Your Right to Get Your Consumer Report</strong>

        <p class="mt-3">
          The consumer reporting agency Momnt Technologies, Inc. (“Momnt”) contacted that provided
          information that influenced our decision in whole or in part was:
          TransUnion, P.O. Box 1000, Chester, PA 19016,
          telephone number (toll-free) 800-888-4213.
          The consumer reporting agency played no part in our decision and is unable to supply
          the specific reasons why we have denied credit to you. You have a right under the
          Fair Credit Reporting Act to know the information contained in your credit file
          at the consumer reporting agency. You also have a right to a free copy of your
          report from the consumer reporting agency if you request it no later than 60
          days after you receive this notice. If you find that any information contained
          in the report you receive is inaccurate or incomplete, you have the right to
          dispute the matter with the reporting agency. Any questions regarding such
          information should be directed to TransUnion.
        </p>

        <p>
          This notice is being provided by {{ brandObject.legal_business_name }}. If you have
          any questions regarding this notice, you should contact {{ brandObject.brand_name }}
          directly at:
        </p>

        <consumer-loan-apply-wizard-aan-address :brand-data="brandObject" />

        <p class="mt-3">
          We also obtained your credit score from TransUnion and used it in making our
          credit decision. Your credit score is a number that reflects
          the information in your consumer report. Your credit score can change,
          depending on how the information in your consumer report changes.
        </p>
        <p class="mb-1">
          Your credit score: {{ creditEngineCreditScore }}
        </p>
        <p class="mb-1">
          Date: {{ dateToday }}
        </p>
        <p class="mb-1">
          Scores range from a low of 300 to a high of 850.
        </p>

        <consumer-loan-apply-wizard-aan-reasons :reasons="creditEngineReasons" />

        <consumer-loan-apply-wizard-aan-notice />

        <p class="mt-3">
          Momnt Technologies, Inc.
        </p>
      </v-card-text>
    </v-card>

    <custom-button
      full-width
      class="continue-button"
      @click="confirmConsent">
      I have read and understand this message
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyWizardAanAddress
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Address.vue';
import ConsumerLoanApplyWizardAanNotice
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Notice.vue';
import ConsumerLoanApplyWizardAanReasons
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Reasons.vue';
import LenderList
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/LenderList.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsumerLoanApplyMessagesAanMixin
  from '@/mixins/Consumer/LoanApply/ConsumerLoanApplyMessagesAanMixin';
import CreditEngineHardPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import CreditEngineHardPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullResultsDataMixin';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import { get } from 'lodash';
import moment from 'moment';
import DATE_CONSTANTS from '@/constants/DateConstants';
import { LenderAddressInterface } from '@/interfaces/consumer/LenderAddressInterface';

export default defineComponent({
  name: 'HardApprovedForLessMsg',

  components: {
    CustomButton,
    ConsumerLoanApplyWizardAanAddress,
    ConsumerLoanApplyWizardAanReasons,
    ConsumerLoanApplyWizardAanNotice,
    LenderList,
  },

  mixins: [
    CreditEngineHardPullResultsDataMixin,
    CreditEngineHardPullApprovedAmountMixin,
    ConsumerLoanApplyMessagesAanMixin,
    GetHumanReadableIdLabelMixin,
  ],

  props: {
    onConsent: { type: Function, default: () => null },
  },

  data() {
    const consentTypes: Array<number> = [];
    return {
      consentApproved: false,
      consentTypes,
    };
  },

  computed: {
    creditEngineReasons(): string[] {
      return get(this.creditEngineHardPullResults, 'reasons', []);
    },
    creditEngineCreditScore(): string {
      return get(this.creditEngineHardPullResults, 'credit_score', 0);
    },
    brandObject(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    applicantName(): string {
      const { first_name, last_name } = this.loanApplyWizardData;
      return `${first_name} ${last_name}`;
    },
    sevenDaysAdvance(): string {
      return moment().add(7, 'd').format(DATE_CONSTANTS.dateFormat);
    },
    humanReadableApplicationId() {
      return this.getHumanReadableIdLabel(this.consumerHumanReadableApplicationId);
    },
    consumerHumanReadableApplicationId(): string {
      return this.$store.getters['Consumer/getConsumerHumanReadableApplicationId'];
    },
    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },
    lenderInfo(): LenderAddressInterface {
      return this.$store.getters['Consumer/getLenderInfo'];
    },
  },

  async mounted() {
    this.consentTypes = [ConsentTypesEnum.AAA_HARD_PULL_APPROVE_FOR_LESS_ONSCREEN];
    await this.presentConsent();
  },

  methods: {
    async confirmConsent(): Promise<void> {
      const responseStatusConsent = await this.updateConsents();

      if (responseStatusConsent === 201) {
        this.consentApproved = true;

        this.$props.onConsent();
      }
    },

    async updateConsents(): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(this.consentTypes, appId, ConsentEntityTypes.CONSUMER);
    },

    async presentConsent(): Promise<void> {
      const consumer_application_uuid = this.$store.getters[
        'Consumer/getConsumerApplicationId'
      ];

      await postConsentTypes({
        consentTypes: this.consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";

.standard-wizard__step__alert {
  border: 1px solid var(--grayscale-color-2);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.consumer-app-id {
  display: flex;
  justify-content: flex-end;
}

.modal-logo {
  max-width: 15rem;
}

.continue-button {
  margin-top: 2rem;
}

@media only screen and (max-width: 365px) {
  .modal-logo {
    max-width: 10rem;
  }
}
</style>
