<template>
  <!-- This component is for expandable category cards.
    For expandable category tables use Tables.vue -->
  <div class="rate-sheet-options-expandable-categories" data-test="rate-sheet-options">
    <div
      v-for="(category, categoryIndex) in expandableCategories"
      :key="categoryIndex"
      class="card-offers-wrap">
      <cards
        v-for="rateSheet in getCategoryRateSheets(category)"
        :key="rateSheet.uuid"
        :class="categoryIndex === 0 ? 'box-shadow-card' : 'no-shadow-card'"
        :value="value"
        :rate-sheet="rateSheet"
        :disabled="disabled"
        :option-selection="optionSelection"
        :is-buffer-amount="isBufferAmount"
        :approved-amount="approvedAmount"
        :requested-amount="requestedAmount"
        :selected-ratesheet="selectedRatesheet"
        @select="onToggle" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Cards from '@/components/RateSheetOptions/ExpandableCategories/Cards.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import StandardInputMixin from '@/mixins/StandardInputMixin';

export default defineComponent({
  name: 'RateSheetOptionsExpandableCategories',

  components: { Cards },

  mixins: [StandardInputMixin],

  props: {
    rateSheetOptions: { type: Array<RateSheetData>, default: () => [] },
    onSelectItem: { type: Function, default: () => null },
    isBufferAmount: { type: Boolean, default: false },
    requestedAmount: { type: [Number, String], default: 0 },
    approvedAmount: { type: Number, default: 0 },
    optionSelection: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedRatesheet: '',
    };
  },

  computed: {
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters['MerchantPortal/getRateSheets'];
    },

    expandableCategories(): any[] {
      const expandableCategories: any = [];

      this.rateSheetOptions.forEach((rateSheet: RateSheetData) => {
        const { category } = rateSheet.json_description || {};
        if (!expandableCategories.includes(category)) expandableCategories.push(category);
      });

      return expandableCategories.filter(Boolean);
    },
  },

  methods: {
    onToggle(item: RateSheetData) {
      this.selectedRatesheet = item.product_name ? item.product_name : '';
      const value = item === (this.value as unknown) ? {} : item;
      this.$emit('change', value);
      this.onSelectItem(value);
    },

    getCategoryRateSheets(category: string): Array<RateSheetData> {
      return this.rateSheetOptions.filter((rateSheet: RateSheetData) => {
        return rateSheet.json_description?.category === category;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/vuetify/elevation.scss";

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;

  .v-input {
    padding: 0 0.5rem 1rem;
  }
}

.show-cards {
  :deep(.v-expansion-panel__shadow) {
    box-shadow: none;
  }
}

.rate-sheet-options-expandable-categories__card {
  border: none;
  border-radius: 4px;
  margin: 0;
  margin-top: 1.5rem;
}

.rate-sheet-options-expandable-categories {
  :deep(.v-expansion-panels) {
    .standard-collapsable-panel {
      margin-bottom: 0;
    }

    .standard-collapsable-panel__title {
      border-radius: 0 0 5px 5px;
      min-height: 5rem;
      font-style: normal;
      font-weight: 900;
      font-size: $font-size-standard;
      line-height: 100%;
      color: var(--secondary-color);
      font-family: $font-family-sans-serif-name;

      .v-icon {
        margin-left: 1rem;
      }
    }

    :deep(.standard-collapsable-panel__title--2) {
      font-family: 'Source Sans Pro';
      font-size: 1.1rem;
      color: var(--primary-color) !important;
      background-color: var(--grayscale-color-5);
      font-weight: 400;

      .v-expansion-panel-title {
        .v-icon {
          color: var(--primary-color) !important;
        }
      }
    }

    :deep(.standard-collapsable-panel__title--2.v-expansion-panel-title--active) {
      color: var(--grayscale-color-1) !important;

      .v-expansion-panel-title {
        .v-icon {
          color: var(--grayscale-color-1) !important;
        }
      }
    }

    .v-expansion-panel-title {
      .v-icon {
        color: var(--secondary-color);
        font-size: 1.75rem;
      }
    }
  }
}

.card-offers-wrap {
  padding: 1rem;
}
</style>
