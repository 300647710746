<template>
  <div>
    <consumer-loan-apply-wizard-soft-decline-consent-confirmation v-if="consentApproved" />
    <div v-else>
      <v-card
        class="fs-unmask standard-aan-msg"
        data-test="softdeclineAan">
        <v-card-text>
          <v-row
            align="end"
            dense
            class="mb-6">
            <v-col cols="12" md="6">
              <img
                :src="logoUrl"
                alt="Logo"
                style="max-width: 100%;">
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-right">
              <p class="mb-0">
                Application ID: {{ humanReadableApplicationId }}
              </p>
            </v-col>
          </v-row>

          <p v-private class="mb-1">
            {{ applicantFullName }}
          </p>
          <p
            v-private
            v-fs-exclude
            class="mb-1">
            {{ loanApplyWizardData.address_1 }}
          </p>
          <p
            v-private
            v-fs-exclude
            class="mb-1">
            {{ loanApplyWizardData.city }} {{ loanApplyWizardData.state }}
          </p>
          <p v-private v-fs-exclude>
            {{ loanApplyWizardData.zip_code }}
          </p>
          <p>{{ dateToday }}</p>

          <p>Dear {{ applicantFullName }}:</p>
          <p>
            Thank you for your recent application. Your request for a loan was carefully considered
            by the following lenders and we regret that none of them were able
            to approve your application at this time:
          </p>
          <div class="mb-3">
            <lender-list :lenders="lenders" />
          </div>
          <p>
            You may request a statement of specific reasons why each lender denied your application
            for credit if you do so within 60 days from the date you receive this letter.
            We will provide you with the statement of reasons within 30 days
            after receiving your request. Please direct your request to
            <a
              v-fs-exclude
              :href="`mailto:${brandObject.footer_object.support_email}`"
              class="aan-address__mail">
              {{ brandObject.footer_object.support_email }}.
            </a>
          </p>
          <strong class="aan-consumer-rights-color">Your Right to Get Your Consumer Report</strong>
          <p class="mt-3">
            The consumer reporting agency Momnt Technologies, Inc. (“Momnt”) contacted that
            provided information that influenced our decision in whole or in part was TransUnion,
            P.O. Box 1000, Chester, PA 19016, telephone number 800-888-4213. The consumer
            reporting agency played no part in our decision and is unable to supply the
            specific reasons why we have denied credit to you. You have a right under the Fair
            Credit Reporting Act to know the information contained in your credit file at the
            consumer reporting agency. You also have a right to a free copy of your report from
            the consumer reporting agency if you request it no later than 60 days after you receive
            this notice. If you find that any information contained in the report you receive is
            inaccurate or incomplete, you have the right to dispute the matter with the reporting
            agency. Any questions regarding such information should be directed to TransUnion.
          </p>
          <p>
            This notice is being provided by {{ brandObject.legal_business_name }}. If you have
            any questions regarding this notice, you should contact {{ brandObject.brand_name }}
            directly at:
          </p>

          <consumer-loan-apply-wizard-aan-address :brand-data="brandObject" />

          <p class="mt-3">
            We also obtained your credit score from TransUnion and used it in making our credit
            decision. Your credit score is a number that reflects the information in your consumer
            report. Your credit score can change, depending on how the information in your consumer
            report changes.
          </p>
          <p>Your credit score: {{ creditEngineCreditScore }}</p>
          <p>Date: {{ dateToday }}</p>
          <p>Scores range from a low of 300 to a high of 850.</p>

          <consumer-loan-apply-wizard-aan-reasons :reasons="creditEngineReasons" />

          <consumer-loan-apply-wizard-aan-notice />

          <p>Momnt Technologies, Inc.</p>
        </v-card-text>
      </v-card>

      <custom-button
        full-width
        class="continue-button"
        @click="confirmConsent">
        I have read and understand this message
      </custom-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyWizardAanAddress
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Address.vue';
import ConsumerLoanApplyWizardAanReasons
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Reasons.vue';
import ConsumerLoanApplyWizardSoftDeclineConsentConfirmation
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/SoftDeclineConsentConfirmation.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import CreditEngineSoftPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullResultsDataMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import { get } from 'lodash';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { LenderAddressInterface } from '@/interfaces/consumer/LenderAddressInterface';
import LenderList
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/LenderList.vue';
import ConsumerLoanApplyWizardAanNotice
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/Notice.vue';

export default defineComponent({
  name: 'SoftDeclineMsg',
  components: {
    CustomButton,
    ConsumerLoanApplyWizardSoftDeclineConsentConfirmation,
    ConsumerLoanApplyWizardAanReasons,
    ConsumerLoanApplyWizardAanAddress,
    LenderList,
    ConsumerLoanApplyWizardAanNotice,
  },
  mixins: [
    DateTimeMixin,
    CreditEngineSoftPullResultsDataMixin,
    GetHumanReadableIdLabelMixin,
  ],
  props: {
    onConsent: { type: Function, default: () => null },
  },
  data() {
    const consentTypes: Array<number> = [];
    const lenders : Array<LenderAddressInterface> = [];
    return {
      consentApproved: false,
      consentTypes,
      lenders,
      serviceAddress: null,
    };
  },
  computed: {
    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    creditEngineReasons(): string[] {
      return get(this.creditEngineSoftPullResults, 'reasons', []);
    },
    creditEngineCreditScore(): string {
      return get(this.creditEngineSoftPullResults, 'credit_score', 0);
    },
    brandObject(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    applicantFullName(): string {
      const { first_name, last_name } = this.loanApplyWizardData;
      return `${first_name} ${last_name}`;
    },
    humanReadableApplicationId() {
      return this.getHumanReadableIdLabel(this.consumerHumanReadableApplicationId);
    },
    consumerHumanReadableApplicationId(): string {
      return this.$store.getters['Consumer/getConsumerHumanReadableApplicationId'];
    },
    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },
  },
  watch: {
    brandObject: {
      handler() {
        this.serviceAddress = this.brandObject.servicing_address.split(',');
      },
      immediate: true,
    },
  },
  async mounted() {
    this.consentTypes = [ConsentTypesEnum.AAN_SOFT_PULL_DECLINE_ONSCREEN];
    await this.presentConsent();
  },
  async created() {
    this.lenders = await this.$store.dispatch('Consumer/getActiveLenders');
  },
  methods: {
    async confirmConsent(): Promise<void> {
      const responseStatusConsent = await this.updateConsents();

      if (responseStatusConsent === 201) {
        this.consentApproved = true;

        this.$props.onConsent();
      }
    },
    async updateConsents(): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(this.consentTypes, appId, ConsentEntityTypes.CONSUMER);
    },
    async presentConsent(): Promise<void> {
      const consumer_application_uuid = this.$store.getters['Consumer/getConsumerApplicationId'];

      await postConsentTypes({
        consentTypes: this.consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";

.continue-button {
  margin-top: 2rem;
}
</style>
