<template>
  <custom-dialog
    :content-class="`offer-code-dialog ${noMargin ? 'ma-0' : ''}`"
    :dialog="true"
    :header-style="headerStyle"
    width="1200px"
    @cancel="$emit('close')">
    <template #title>
      <span>{{ title }}</span>
    </template>
    <template #body>
      <div class="mb-2 px-4">
        <div class="font-italic mt-3">
          <span>
            You must select at least two loan products,
            and the maximum number is seven.
          </span>
        </div>
      </div>
      <div data-test="offerCodeProducts">
        <rate-sheet-expandable-categories-tables
          :rate-sheet-options="rateSheets"
          is-from-offer-codes
          button-toggle
          :disable-add-btns="disableAddBtns"
          :mobile-breakpoint="800"
          @toggle="onToggle" />
      </div>
    </template>
    <template #actions>
      <custom-button
        class="full-width"
        data-test="saveOfferCodeProducts"
        :disabled="saveBtnDisabled"
        @click="onSave">
        Save And Close
      </custom-button>
    </template>
  </custom-dialog>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomDialog from '@/components/Dialogs/index.vue';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { defineComponent } from 'vue';
import RateSheetExpandableCategoriesTables from
  '@/components/RateSheetOptions/ExpandableCategories/ExpandableCategoriesTables.vue';

const LIMIT_WARNING_ALERT = {
  text: `You have reached the maximum number of seven loan products in this Offer code.
    You can continue editing, or save and close.`,
  type: GlobalAlertTypes.WARNING,
};

const MAX_LOANS = 7;

export default defineComponent({
  name: 'ProductFormDialog',
  components: {
    CustomButton,
    CustomDialog,
    RateSheetExpandableCategoriesTables,
  },
  props: {
    rateSheets: { type: Array<RateSheetData>, default: () => [] },
    editing: { type: Boolean, default: false },
    name: { type: String, default: '' },
  },
  data() {
    return {
      initialSelectedIds: [] as Array<number>,
      selectedIds: [] as Array<number>,
      headerStyle: 'text-align: left;',
      alertId: 0,
    };
  },
  computed: {
    disableAddBtns(): boolean {
      return this.selectedIds.length >= MAX_LOANS;
    },
    title() {
      if (this.editing) {
        return `Now editing offer code ${this.name}`;
      }
      return `Select the loan products you would like to add to offer code ${this.name}`;
    },
    isDirty(): boolean {
      if (this.initialSelectedIds.length !== this.selectedIds.length) return true;
      return !this.initialSelectedIds.every((id: any) => this.selectedIds.includes(id));
    },
    saveBtnDisabled(): boolean {
      return !this.isDirty || this.selectedIds.length < 2;
    },
    noMargin(): boolean {
      return this.$vuetify.display.width < 500;
    },
  },
  mounted() {
    this.rateSheets.forEach((it: any) => {
      if (it.is_selected) {
        const id = it.rate_sheet_merchant_product_price_id;
        this.initialSelectedIds.push(id);
        this.selectedIds.push(id);
      }
    });
  },
  methods: {
    onSave() {
      this.$emit('save');
      this.clearAlert();
    },
    async onToggle() {
      const selectedIds: Array<number> = [];
      this.rateSheets.forEach((it: RateSheetData) => {
        if (it.is_selected) selectedIds.push(it.rate_sheet_merchant_product_price_id);
      });
      this.selectedIds = selectedIds;
      if (selectedIds.length === MAX_LOANS) {
        const alert = await this.$store.dispatch('Ui/addGlobalAlert', LIMIT_WARNING_ALERT);
        this.alertId = alert.id;
      } else {
        this.clearAlert();
      }
    },
    clearAlert() {
      if (this.alertId) this.$store.dispatch('Ui/removeGlobalAlert', this.alertId);
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-table__mobile-row:nth-last-child(1)) {
  width: 100%!important;
  justify-content: center;
}
</style>

<style lang="scss">
.offer-code-dialog {
  .v-card-text {
    &.dialog-body-wrap {
      padding: 0 !important;
      background-color: var(--grayscale-color-5);
    }
  }

  .v-card-actions {
    padding-top: 1rem !important;
    background-color: var(--grayscale-color-5);
  }
}
</style>
