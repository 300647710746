<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    location="center">
    <template #activator="{ props }">
      <FallbackBadge
        v-bind="{ ...props, ...$attrs }"
        class="cursor-pointer"
        :from
        :to />
    </template>
    <div class="product-attribute-tooltip">
      <div class="product-attribute-tooltip-header">
        <FallbackBadge :from :to />
        <v-icon
          type="button"
          color="var(--grayscale-color-1)"
          size="20"
          @click="openMenu = false">
          mdi-close
        </v-icon>
      </div>
      <div class="product-attribute-tooltip-body">
        <p>
          Products with this identifier are {{ from }} Year Term products where a {{ to }} Year Term Product will be offered
          instead in the following states: Iowa, Maryland, and Washington D.C.
        </p>
        <p>This complies with state law regarding a maximum APR cap.</p>
        <p>This will happen automatically, and both products have the same merchant fee.</p>
      </div>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FallbackBadge from './FallbackBadge.vue';

interface FallbackBadgeProps {
  from?: string | number;
  to?: string | number;
}

withDefaults(defineProps<FallbackBadgeProps>(), {
  from: 7,
  to: 4,
});

const openMenu = ref(false);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/product-attribute-tooltip"
</style>
