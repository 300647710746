<template>
  <v-menu v-model="menuOpen" :close-on-content-click="false">
    <template #activator="{ props }">
      <button v-bind="props" class="filter-badge">
        Filter By
        <v-icon class="ml-2">
          mdi-filter-variant
        </v-icon>
      </button>
    </template>
    <v-list width="15.125rem">
      <v-list-item>
        <template #prepend>
          <v-list-item-action start>
            <v-checkbox-btn v-model="buffer" />
          </v-list-item-action>
        </template>
        <v-list-item-title>
          <SeventyFiveKBadge />
        </v-list-item-title>
      </v-list-item>
      <div class="pa-4 pb-0">
        <CustomButton
          size="small"
          full-width
          :disabled
          @click="sendFilter">
          Apply
        </CustomButton>
        <CustomButton
          class="mt-4"
          variant="link"
          size="small"
          full-width
          :disabled="!buffer"
          @click="buffer = false">
          Clear Selection
        </CustomButton>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import SeventyFiveKBadge from '@/components/Merchant/Portal/RateSheets/SeventyFiveKBadge.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

const componentProps = defineProps<{
  active: boolean;
}>();
const emit = defineEmits(['toggle']);

const menuOpen = ref(false);
const buffer = ref(false);

const disabled = computed(() => buffer.value === componentProps.active);

const sendFilter = () => {
  emit('toggle', buffer.value);
  menuOpen.value = false;
};

const resetBuffer = () => {
  buffer.value = componentProps.active;
};

watch(menuOpen, val => {
  if (!val) {
    resetBuffer();
  }
});

watch(() => componentProps.active, val => {
  buffer.value = val;
});
</script>

<style lang="scss" scoped>
.filter-badge {
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--grayscale-color-1);
  background-color: var(--grayscale-color-5);
}
</style>
