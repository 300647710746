<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    location="center">
    <template #activator="{ props }">
      <SeventyFiveKBadge v-bind="{ ...props, ...$attrs }" class="cursor-pointer" />
    </template>
    <div class="product-attribute-tooltip">
      <div class="product-attribute-tooltip-header">
        <SeventyFiveKBadge />
        <v-icon
          type="button"
          color="var(--grayscale-color-1)"
          size="20"
          @click="openMenu = false">
          mdi-close
        </v-icon>
      </div>
      <div class="product-attribute-tooltip-body">
        <p>
          Products with this identifier can be used for customers
          seeking a loan of up to $75,000.00.
        </p>
        <p>
          To enable $75k loans, activate two or more eligible
          products. Once activated on, customers can request up to
          $75,000.00.
        </p>
      </div>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SeventyFiveKBadge from './SeventyFiveKBadge.vue';

const openMenu = ref(false);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/product-attribute-tooltip"
</style>
