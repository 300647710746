<template>
  <div class="align-center d-flex" :class="`justify-${align}`">
    <span>{{ title }}</span>
    <v-icon v-if="tooltip" size="1rem">
      mdi-help-circle-outline
    </v-icon>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TableHeaderWithTooltip',

  props: {
    title: { type: String, default: '' },
    align: { type: String, default: 'start' },
    tooltip: { type: String, default: '' },
  },
});
</script>

<style lang="scss" scoped>
.v-icon {
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  color: var(--grayscale-color-2);
  cursor: pointer;
}
</style>
