<template>
  <v-menu
    v-if="formattedId"
    v-model="showMenu"
    :close-on-content-click="false"
    :width="width"
    :offset="[-35, 0]"
    z-index="201">
    <template #activator="{ props }">
      <button class="consumer-activity-interactable" v-bind="props">
        {{ formattedId }}
      </button>
    </template>
    <v-card class="application-summary-popup fs-unmask">
      <v-card-title class="dialog-popup-title">
        <span>{{ formattedId }}</span>
        <v-icon tag="button" @click="showMenu = false">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="pb-0">
        <progress-indicator v-if="loading" type="circular" />
        <template v-else-if="application">
          <status-chip :status="application.application_status" class="my-4" />
          <div class="application-summary-popup-item">
            <strong>Sent To: </strong>
            <span :class="{ 'fs-exclude': isPhoneNumber }">{{ application.sent_to }}</span>
          </div>
          <div class="application-summary-popup-item">
            <strong>Sent On:</strong> {{ formattedDate }}
          </div>
          <div class="application-summary-popup-item">
            <strong>Sent By:</strong> {{ application.sent_by }}
          </div>
          <div v-if="application.offer_code" class="application-summary-popup-item">
            <strong>Offer Code:</strong> {{ application.offer_code }}
          </div>
        </template>
      </v-card-text>

      <v-card-actions class="bg-white pa-4">
        <custom-button
          size="small"
          full-width
          @click="showMenu = false">
          Close
        </custom-button>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import moment from 'moment';
import store from '@/store';
import { useDisplay } from 'vuetify';
import { getInvitationDetails } from '@/api/consumerActivity';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetHumanReadableIdLabel from '@/utils/GetHumanReadableIdLabel';
import { InvitationDetails } from '@/interfaces/ConsumerActivity';
import ProgressIndicator from '@/components/ProgressIndicator.vue';
import isPhoneNumber from '@/validators/phone_number';
import StatusChip from './StatusChip.vue';

export default defineComponent({
  components: { CustomButton, StatusChip, ProgressIndicator },

  props: {
    idNumber: { type: String, default: null },
    invitationId: { type: String, default: null },
  },

  setup(props) {
    const showMenu = ref(false);
    const loading = ref(false);
    const application = ref<InvitationDetails | null>(null);

    const formattedId = computed(() => GetHumanReadableIdLabel(props.idNumber));
    const formattedDate = computed(() => moment(application.value?.sent_on).format('MM-DD-YYYY HH:mm a'));

    const { xs } = useDisplay();
    const width = computed(() => {
      return xs.value ? '95vw' : '23.875rem';
    });

    const merchantId = computed(() => store.getters['Auth/getMerchantUuid']);
    watch(showMenu, async (newValue: boolean) => {
      if (newValue) {
        loading.value = true;
        const { data } = await getInvitationDetails(merchantId.value, props.invitationId);
        application.value = data;
        loading.value = false;
      }
    });

    return {
      application, showMenu, formattedId, formattedDate, loading, isPhoneNumber, width,
    };
  },
});
</script>

<style lang="scss" scoped>
.application-summary-popup {
  &-item {
    padding: 1rem 0;
    text-align: left;
    color: var(--grayscale-color-1);
    border-top: 1px solid var(--grayscale-color-3);
  }
}
</style>
