import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45e33dbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "rate-sheet-options-expandable-categories",
  "data-test": "rate-sheet-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cards = _resolveComponent("cards")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandableCategories, (category, categoryIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: categoryIndex,
        class: "card-offers-wrap"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCategoryRateSheets(category), (rateSheet) => {
          return (_openBlock(), _createBlock(_component_cards, {
            key: rateSheet.uuid,
            class: _normalizeClass(categoryIndex === 0 ? 'box-shadow-card' : 'no-shadow-card'),
            value: _ctx.value,
            "rate-sheet": rateSheet,
            disabled: _ctx.disabled,
            "option-selection": _ctx.optionSelection,
            "is-buffer-amount": _ctx.isBufferAmount,
            "approved-amount": _ctx.approvedAmount,
            "requested-amount": _ctx.requestedAmount,
            "selected-ratesheet": _ctx.selectedRatesheet,
            onSelect: _ctx.onToggle
          }, null, 8, ["class", "value", "rate-sheet", "disabled", "option-selection", "is-buffer-amount", "approved-amount", "requested-amount", "selected-ratesheet", "onSelect"]))
        }), 128))
      ]))
    }), 128))
  ]))
}